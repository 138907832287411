import React, { useEffect, useRef, useState } from "react";
import { IconButton, Box } from "@mui/material";
import { Edit, Delete, ElevatorSharp } from "@mui/icons-material";
import { toast } from "react-toastify";
import { getApiData, postFormData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/reducers/auth/actions";
const SingleImageUpload = ({
  budgetId,
  budget,
  budgetEditId,
  isUpdateDraftProposal,
  setBudgets,
  isCreateProposal,
}) => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const { setProposalDetails } = authActions;
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  const fileInputRef = useRef(null);

  const handleImageChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      await handleImageUpload(file);
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file_1", file);
    formData.append("type", "budgetItemDetails");
    formData.append("project_id", budgetEditId);

    try {
      const response = await postFormData(
        Setting.endpoints.projectFiles,
        formData
      );
      if (response.success) {
        toast.success("File uploaded successfully");
        if (!isUpdateDraftProposal) {
          const newImageObject = response.images[0]; // Assuming the API returns images array with the new image

          // Handle the case where photo_origin is empty
          const updatedPhotoOrigin =
            budget.photo_origin.length > 0
              ? budget.photo_origin.map((img) =>
                  img.id === 0 ? newImageObject : img
                )
              : [newImageObject];

          const updatedBudget = {
            ...budget,
            photo_origin: updatedPhotoOrigin,
          };

          // Update budgets state
          // setBudgets((prevBudgets) => ({
          //   ...prevBudgets,
          //   [budgetEditId]: updatedBudget,
          // }));

          // Update proposalDetails state
          dispatch(
            setProposalDetails({
              ...proposalDetails,
              budgets: {
                ...proposalDetails.budgets,
                [budgetEditId]: updatedBudget,
              },
            })
          );
        }
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      console.log("An error occurred", error);
      toast.error("An error occurred during file upload");
    }
  };

  const handleImageDelete = async () => {
    try {
      const id = isUpdateDraftProposal
        ? budget?.budget_image_id
        : budget?.photo_origin[0]?.id;

      const response = await getApiData(
        Setting.endpoints.deleteProjectImageById(id),
        "POST"
      );
      if (response.success) {
        toast.success("Image deleted successfully");

        if (!isUpdateDraftProposal) {
          let updatedPhotoOrigin = budget.photo_origin;

          // Check if the first image does not have an id of 0, then remove it
          if (budget.photo_origin[0]?.id !== 0) {
            updatedPhotoOrigin = budget.photo_origin.filter(
              (_, index) => index !== 0
            );
          }

          const updatedBudget = {
            ...budget,
            photo_origin: updatedPhotoOrigin,
          };

          // Update budgets state
          console.log("delet case", updatedBudget);
          // setBudgets((prevBudgets) => ({
          //   ...prevBudgets,
          //   [budgetEditId]: updatedBudget,
          // }));

          // Update proposalDetails state
          dispatch(
            setProposalDetails({
              ...proposalDetails,
              budgets: {
                ...proposalDetails.budgets,
                [budgetEditId]: updatedBudget,
              },
            })
          );
        }
      }
    } catch (error) {
      console.error("Error during image deletion:", error);
      toast.error(error.message || "An error occurred during image deletion");
    }
  };

  const handleEditClick = () => {
    if (budget?.photo_origin?.length < 2) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      toast.error("You can upload only one image");
    }
  };
  const hasNonDefaultImage = budget?.photo_origin.some((img) => img.id !== 0);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          gap: "8px",
          p: 1,
        }}
      >
        {!hasNonDefaultImage && !isUpdateDraftProposal && !isCreateProposal && (
          <IconButton
            size="small"
            style={{ color: "white" }}
            onClick={handleEditClick}
          >
            <Edit />
          </IconButton>
        )}

        {/* Conditionally render Delete button */}
        {hasNonDefaultImage && !isUpdateDraftProposal && !isCreateProposal && (
          <IconButton
            size="small"
            style={{ color: "white" }}
            onClick={handleImageDelete}
          >
            <Delete />
          </IconButton>
        )}
      </Box>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
    </Box>
  );
};

export default SingleImageUpload;
