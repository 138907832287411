import moment from "moment";
import { CHANGE_REQUEST_STATUS_DATA } from "../../../../config/constants";
import useStyles from "../styles";
import { Circle } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";

const ChangelogStatus = ({ status, moveInDate }) => {
  const classes = useStyles();
  return (
    <div className={classes.statusContainer}>
      <div className={classes.statusStart}>
        <Circle
          style={{
            fill: CHANGE_REQUEST_STATUS_DATA[status]?.color,
            fontSize: 15,
          }}
        />
        <Typography className={classes.status}>
          {CHANGE_REQUEST_STATUS_DATA[status]?.label || "Last Updated"}
        </Typography>
      </div>

      <div className={classes.statusEnd}>
        {moveInDate &&
        <Typography>
          <span className={classes.statusKey}>New Move-In Date:</span>{" "}
          {moveInDate ? moment(moveInDate)?.format("MMMM D, YYYY") : "-"}
        </Typography>
        }
      </div>
    </div>
  );
};

export default ChangelogStatus;
