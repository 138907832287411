const baseUrl = process.env.REACT_APP_BASE_URL;
const newBaseUrl = process.env.REACT_APP_NEW_BASE_URL;
export const Setting = {
  baseUrl,
  api: baseUrl,
  newBaseUrl,
  socketURL: baseUrl, ///'https://reno-home.azurewebsites.net',
  GOOLE_MAPS_KEY: "AIzaSyBO1ZlrBp68Oj02lrpoF4gJ7DbuzaFQLNA",
  FACEBOOK_APP_ID: "",
  GOOGLE_CLIENT_ID:
    "271291217173-edt0r9crj6puar4o75r2ipagf0inimue.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET_KEY: "",

  endpoints: {
    me: "api/user/me",
    login: "api/user/login",
    signup: "api/user/signup",
    getContractorTermsAndConditions: "api/user/contractor-terms-and-conditions",
    getHomeownerTermsAndConditions: "api/user/homeowner-terms-and-conditions",
    googleData: "auth/google",
    verifyOtp: "api/user/verify-otp",
    resendOtp: "api/user/resend-otp",
    updatepassword: "api/user/update-password", 
    updatePasswordOtp: "api/user/update-password-otp",
    addContractorDetails: "api/contractor/add-contractor-details",
    addPortfolio: "api/contractor/add-portfolio",
    addBillingInfo: "api/contractor/add-billing-info",
    forgotPassword: "api/user/forgot-password",
    contarctorById: "api/contractor", //not used
    deleteportfolio: "api/contractor/delete-portfolio",
    updatesecuritysettings: "api/user/update-security-settings", //not used
    updateUserSetting: "api/user/update-user-settings",
    twoFactorSetting: "api/user/verified-two-factor",
    logoutall: "api/user/logout-all",
    logindeviceslist: "api/user/login-devices-list",
    projectlist: "api/project/project-list",
    milestoneProposalList: "api/project/milestone-project-list",
    singleDeviceLogout: "api/user/other-devices-logout/",
    createproposal: "api/contractor/create-proposal",
    directproposal: "api/contractor/create-direct-proposal",
    updateProposal: "api/contractor/update-direct-proposal",
    createDraftProposal: "api/contractor/create-draft-proposal",
    draftProposalList: "api/project/proposal-drafts-list",
    addFCMToken: "api/user/add-fcm-token",
    logout: "api/user/logout",
    createMilestone: "api/project/create-milestone-project",
    createBudget: "api/project/create-proposal-budget-item", //new not used
    projectDetails: "api/project/my-projects",        //new not used
    budgetList: "api/project/list-proposal-budget-item",
    uploadTemplate: "api/project/upload-template", //not used
    deleteTemplate: "api/project/upload-template-delete",
    listcontractorproject: "api/project/list-contractor-project",
    deleteImage: "api/contractor/delete-image",
    deleteMilestone: "api/project/milestone-proposal-delete",
    deleteBudget: "api/project/delete-budget-item",
    proposalDetails: "/api/project/project-details",
    getProject: "api/project/get-projects",
    deleteSummaryImage: "api/user/delete-image", //not used
    alreadySentProposal: "api/project/already-proposal", //not used
    notificationList: "api/user/notifications-list",
    paymentList: "api/stripe/payment-transaction-list",
    paymentRequest: "api/contractor/add-payment-request", //not used
    milestoneCount: "api/project/project-common-data",
    submitMilestone: "api/project/milestone-delivery",
    updateMilestone: "api/project/single-milestone-update",
    createSingleBudget: "api/project/create-single-proposal-budget-item",
    updateSingleBudget: "api/project/single-proposal-budget-update",
    budgetDelete: "api/project/delete-budget-item",
    milestoneDelete: "api/project/milestone-proposal-delete",
    contractorStates: "api/contractor/stats",
    createUser: "api/contractor/user",
    availableContractors: "/api/admin/available-contractors", //not used
    assignContractors: "api/project/project-submit-contractor", //not used
    approveRejectProject: "api/project/status",
    notes: "api/project/notes",
    projectFiles: "api/project/files",
    startMilestone: "api/milestone/status",
    deleteProjectImage: "api/user/delete-image",
    getBudgetItem: "api/project/list-proposal-budget-item",
    deleteBudgetItem: "api/project/delete-budget-item",
    changeRequest: {
      list: (projectId) =>
        `api/v2/change-request/project/${projectId}?role=contractor`,
      approve: (projectId) => `api/v2/change-request/${projectId}/event/ACCEPT`,
      details: (changeRequestId) => `api/v2/change-request/${changeRequestId}`,
    },
    getPaymentScheduleDetails: ({ contractorId, proposalId }) =>
      `api/v2/contractors/${contractorId}/payments?proposalId=${proposalId}`,   //not used
    inspections: "api/v2/project/inspections",
    deleteProjectImageById: (imageId) => `api/project/delete-files/${imageId}`,
    inspectionDetails: (inspectionId)=> `api/v2/project/inspection/${inspectionId}`,
    milestoneUpdates: "api/project/milestone-updates",
    milestoneUpdatesSummary: "/api/project/milestone-updates-summary",
    balanceSheetDetails: ({ contractorId }) => `api/v2/contractors/${contractorId}/balanceSheet`,
    uploadDocument: `api/user/document-upload`,
    deleteDocument: `api/user/delete-document`,
  },

  JS_Regex: {
    email_Regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    pwd1_Regex: /^.{8,}$/, // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    phone_Regex: /^(\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10})?$/,
    alphabatic_Regex: /^[A-Za-z]+$/,
  },

  page_name: {},
};
